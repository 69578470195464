import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import messages from 'vee-validate/dist/locale/en.json';
import semver from 'semver';
//Validation rules will be included here. there are preset validations that can be fetched from
//vee-validate/dist/rules as well as custom building validations.

extend( 'required', {
  ...rules.required,
  message: messages.required
} );

extend( 'min_value', {
  ...rules.min_value,
  message: messages.min_value
} );
extend( 'min', {
  ...rules.min,
  message: messages.min
} );
extend( 'max', {
  ...rules.max,
  message: messages.max
} );
extend( 'max_value', {
  ...rules.max_value,
  message: messages.max_value
} );
extend( 'numeric', {
  ...rules.numeric,
  message: messages.numeric
} );
extend( 'regex', {
  ...rules.regex,
  message: messages.regex
} );

extend( 'email', {
  ...rules.email,
  message: messages.email
} );
// Custom rules
extend( 'semver-strict', value => {
  if( semver.valid( value ) && semver.satisfies( value, value ) ) {
    return true;
  }
  return'Invalid version number, please use the x.x.x format';
} );
extend( 'typeOfString', value => {
  if( typeof value === 'string' ) {
    return true;
  }
  return'value must be string';
} );
extend( 'required_date', {
  validate( value ) {
    if(
      value !== null &&
      value !== undefined &&
      value !== '' &&
      value !== 'NaN-aN-aN' &&
      value !== 'NaN-NaN-NaNTNaN:NaN' &&
      value !== 'Invalid Date' &&
      new Date( value ) !== 'Invalid Date'
    ) {
      return true;
    }
    return'Date is required.';
  }
} );
extend( 'date_min_value', {
  params: [ 'minDateString' ],
  validate( value, { minDateString } ) {
    const unformattedDate = new Date( value );
    //need to to account for timezone offset when checking date and time values.
    const currentDate = new Date(
      unformattedDate.setMinutes(
        unformattedDate.getMinutes() + unformattedDate.getTimezoneOffset()
      )
    );
    const targetDate = new Date( minDateString );
    if( currentDate >= targetDate ) {
      return true;
    }
    return`date cannot be less than ${targetDate}`;
  }
} );
extend( 'date_max_value', {
  params: [ 'maxDateString' ],
  validate( value, { maxDateString } ) {
    const currentDate = new Date( value );
    const targetDate = new Date( maxDateString );
    if( currentDate <= targetDate ) {
      return true;
    }
    return`date cannot be greater than ${targetDate}`;
  }
} );

extend( 'no_spaces', value => {
  const hasSpace = value.includes( ' ' );
  if( !hasSpace ) {
    return true;
  }
  return'No spaces allowed.';
} );

extend( 'contains', {
  params: [ 'word' ],
  validate( value, { word } ) {
    const includes = value.includes( word );
    return includes || `Value must contain "${word}"`;
  }
} );

extend( 'iso8601', {
  validate( value ) {
    if( !value ) {
      return true;
    }
    // Regular expression for validating ISO 8601 dates
    const iso8601Regex = /^(\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}(:\d{2}(\.\d{1,3})?)?(Z|([+-]\d{2}:\d{2})))?)$/;
    return iso8601Regex.test( value );
  },
  message: 'Date must be a valid date (yyyy-mm-dd).'
} );
