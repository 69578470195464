

const { get } = require( 'lodash' );
const { getConfig } = require( './config' );

// config properties.
const module_configs = getConfig( 'modules' );
const backend_url = getConfig( 'backend_url' );

//config facade. This is what will be exposed.
const config_facade = {
  forge:        module_configs.forge,
  time_machine: module_configs.time_machine,
  catapult:     module_configs.catapult,
  backend_url
};

module.exports = {
  config_facade
};
