<template>
  <transition :name="fade ? 'fade' : null" :appear="true">
    <div
      v-if="isShowed"
      :class="toastClasses"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div v-if="header !== undefined || $slots.header" class="toast-header">
        <slot name="header">
          <strong class="mfe-auto">{{ header }}</strong>
        </slot>
        <CButtonClose v-if="closeButton" @click="close()" class="mfs-2 mb-1" />
      </div>
      <div class="toast-body">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    autohide: {
      type:      [ Number, Boolean ],
      validator: val => typeof val === 'number' || val === false,
    },
    closeButton: {
      type:    Boolean,
      default: true,
    },
    fade: {
      type:    Boolean,
      default: true,
    },
    color: {
      type:    String,
      default: '',
    },
    show:   Boolean,
    header: String,
    value:  {},
  },

  created() {
    this.setValue();
  },
  watch: {
    value() {
      this.setValue();
    },
  },
  data() {
    return{
      isShowed:     Boolean,
      hideTimeout:  null,
      closeTimeout: null,
    };
  },

  computed: {
    toastClasses() {
      return[ 'toast', { [ `toast-${this.color}` ]: !!this.color } ];
    },
  },
  methods: {
    setValue() {
      if( this.isShowed !== this.value ) {
        this.isShowed = this.value;
      }
      if( this.isShowed ) {
        this.display();
      } else {
        this.close();
      }
    },
    display() {
      this.isShowed = true;
      this.$nextTick( () => {
        if( this.autohide ) {
          this.setAutohide();
        }
      } );
    },
    close() {
      this.isShowed = false;
      this.$emit( 'input', false );
    },
    setHiddingMode() {
      this.isShowed = false;
      this.closeTimeout = setTimeout( () => this.close(), 1500 );
    },
    restoreHiddingToast() {
      clearTimeout( this.closeTimeout );
      this.display();
    },
    onHover() {
      clearTimeout( this.hideTimeout );
    },
    onHoverOut() {
      this.setAutohide();
    },
    setAutohide() {
      this.hideTimeout = setTimeout( () => {
        this.setHiddingMode();
      }, this.autohide );
    },
  },
};
</script>

<style scoped>
.toast {
  opacity: inherit;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
