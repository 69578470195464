import _ from 'lodash';
import semver from 'semver';

const impact_severity_rules = [
  // Significant Impact: Ranked in one version and excluded in the other version
  { name:     "Significant Impact",
    impact:   1,
    evaluate: function( historical, reprocessed ) {
      if( historical.excluded !== reprocessed.excluded ) {
        const message = historical.excluded ?
          `${historical.client_id} was excluded in historical allocations but is now ranked in reprocessed allocations.` :
          `${historical.client_id} was ranked in historical allocations but is now excluded in reprocessed allocations.`;
        return{ name: this.name, impact: this.impact, message };
      }
      return null;
    } },
  // Major Impact: Ranked in both versions but in different tiers or excluded in both versions but with different exclusion codes
  { name:   "Major Impact",
    impact: 2,

    evaluate: function( historical, reprocessed ) {
      //if both are ranked but are in different tiers and different ranking categories then it is a major impact
      //if both are excluded but have different exclusion code and different exclusion details then it is also a major impact
      if( !historical.excluded && !reprocessed.excluded && historical.actual_rank !== reprocessed.actual_rank && historical.ranking_category !== reprocessed.ranking_category ) {
        const message = `${historical.client_id} was ranked in rank ${historical.actual_rank} in historical allocations but is now ranked in rank ${reprocessed.actual_rank} in reprocessed allocations.`;
        return{ name: this.name, impact: this.impact, message };
      } if( historical.excluded && reprocessed.excluded && historical.excluded_reason_code !== reprocessed.excluded_reason_code && historical.excluded_reason !== reprocessed.excluded_reason ) {
        const message = `${historical.client_id} is excluded in both historical and reprocessed, but with different exclusion codes.`;
        return{ name: this.name, impact: this.impact, message };
      }
      return null;
    } },
  // Moderate Impact: Both ranked, different ranks, same rank category details
  // OR Both excluded, different exclusion code, same exclusion category details
  { name:     "Moderate Impact",
    impact:   3,
    evaluate: function( historical, reprocessed ) {
      //both ranked, same ranks, different ranks but same category details
      if( !historical.excluded && !reprocessed.excluded && historical.actual_rank !== reprocessed.actual_rank && historical.ranking_category === reprocessed.ranking_category ) {
        const message = `${historical.client_id} was ranked in both historical and reprocessed allocations, with different ranks but have same rank description.`;
        return{ name: this.name, impact: this.impact, message };
      } if( historical.excluded && reprocessed.excluded && historical.excluded_reason_code !== reprocessed.excluded_reason_code && historical.excluded_reason === reprocessed.excluded_reason ) {
        const message = `${historical.client_id} is excluded in both historical and reprocessed allocations, with different exclusion codes but have same exclusion category details.`;
        return{ name: this.name, impact: this.impact, message };
      }
      return null;
    } },
  // Minor Impact: Both ranked in same rank, different rank category details
  // OR Both excluded with same exclusion code but different exclusion category details
  //This most likely indicates a change in wording or description of the rank or exclusion category details
  {
    name:     "Minor Impact",
    impact:   4,
    evaluate: function( historical, reprocessed ) {
      //both ranked, same ranks, different ranks but same category details
      if( !historical.excluded && !reprocessed.excluded && historical.actual_rank === reprocessed.actual_rank && historical.ranking_category !== reprocessed.ranking_category ) {
        const message = `${historical.client_id} was ranked in both historical and reprocessed allocations, with same ranks but have different rank description.`;
        return{ name: this.name, impact: this.impact, message };
      } if( historical.excluded && reprocessed.excluded && historical.excluded_reason_code === reprocessed.excluded_reason_code && historical.excluded_reason !== reprocessed.excluded_reason ) {
        const message = `${historical.client_id} is excluded in both historical and reprocessed allocations, with same exclusion codes but have different exclusion reason.`;
        return{ name: this.name, impact: this.impact, message };
      }
      return null;
    }
  },
  // No Impact: No change between historical and reprocessed allocations, this is the default
  {
    name:     "No Impact",
    impact:   null,
    evaluate: function( historical, reprocessed ) {
      if( ( historical.excluded && reprocessed.excluded && historical.excluded_reason_code === reprocessed.excluded_reason_code && historical.excluded_reason === reprocessed.excluded_reason ) ||
      ( !historical.excluded && !reprocessed.excluded && historical.actual_rank === reprocessed.actual_rank && historical.ranking_category === reprocessed.ranking_category )
      ) {
        const message = `${historical.client_id} has no change between historical and reprocessed allocations.`;
        return{ name: this.name, impact: this.impact, message };
      }
      return null;
    }
  },
  // Unknown Impact: This is the default if no other impact is found, should show up as warning or error
  {
    name:     "Unknown Impact",
    impact:   -1,
    evaluate: function( historical, reprocessed ) {
      const message = `${historical.client_id} has an unknown impact between historical and reprocessed allocations.`;
      return{ name: this.name, impact: this.impact, message, historical, reprocessed };
    }
  }

  // { name: "No Impact", impact: null, message: "No change between historical and reprocessed allocations.", condition: ( item1, item2 ) => true }

];
const hasChildren = obj => Array.isArray( obj.children ) && obj.children.length > 0;
const sortByChildren = ( a, b ) => {
  const hasChildrenDiff = ( hasChildren( b ) ? 1 : 0 ) - ( hasChildren( a ) ? 1 : 0 );
  if( hasChildrenDiff !== 0 ) {
    return hasChildrenDiff;
  }

  // If both have children, sort by name...
  const firstName = a.name.toLowerCase();
  const secondName = b.name.toLowerCase();
  return firstName.localeCompare( secondName );
};
const sortDataByChildren = data => {
  if( Array.isArray( data ) ) {
    return data.slice().sort( sortByChildren ).map( sortDataByChildren ); // Recursively sort the children
  } if( typeof data === 'object' && data !== null ) {
    const clonedObj = { ...data };
    if( Array.isArray( clonedObj.children ) ) {
      clonedObj.children = sortDataByChildren( clonedObj.children ); // Sort the children
    }
    return clonedObj;
  }
  return data;
};
const ImpactUtility = {
  sortDataByChildren,
  isNilOrEmpty: item => _.isNil( item ) || _.isEmpty( item ),

  isNumeric: n => !Number.isNaN( n ) && !Number.isNaN( parseFloat( n ) ),

  typeSafeValue( condition ) {
    // console.log( 'ImpactUtility: typesafing: condition', condition );
    const { _type, value } = condition || {};

    if( this.isNilOrEmpty( _type ) ) return null;
    if( this.isNilOrEmpty( value ) ) return null;

    // If the value is an array, then do not parse it.
    if( Array.isArray( value ) ) return value;
    switch( _type.toLowerCase() ) {
      case 'number':
        const number_value = parseFloat( value );
        if( Number.isInteger( number_value ) ) {
          return parseInt( value );
        }
        return number_value;
      case 'string':
      case 'text':
      case 'password':
        return String( value );
      case 'boolean':
        return( String( value ).toLowerCase() === 'true' ) || ( String( value ).toLowerCase() === 'false' ) ? String( value ).toLowerCase() === 'true' : null;
      case 'datetime':
      case 'date':
        return this.isDateValid( value ) ? parseInt( value ) : new Date( value );
      case 'objectid':
        return/^[0-9a-fA-F]{24}$/.test( value );
      case 'string_array':
      case 'array':
        return Array.isArray( value ) ? value : value.split( ',' );
      case 'number_array':
        return String( value ).split( ',' ).map( num => ( !this.isNilOrEmpty( num ) ? Number( num ) : null ) ).filter( num => !_.isNil( num ) );
      default:
        return null;
    }
  },

  typeOf: item => ( Array.isArray( item ) ? 'array' : typeof item ),

  isDateValid: str => !Number.isNaN( Date.parse( str ) ),

  getNewVersion( versions, versionType, currentVersion ) {
    if( !Array.isArray( versions ) || versions.length === 0 ) {
      return{
        major: '1.0.0',
        minor: '0.1.0',
        patch: '0.0.1'
      }[ versionType ];
    }

    switch( versionType ) {
      case 'major':
        return semver.inc( semver.maxSatisfying( versions, '*' ), 'major' );
      case 'minor':
        return semver.inc( semver.maxSatisfying( versions, `${semver.major( currentVersion )}.x.x` ), 'minor' );
      case 'patch':
        return semver.inc( semver.maxSatisfying( versions, `${semver.major( currentVersion )}.${semver.minor( currentVersion )}.x` ), 'patch' );
    }
  },
  getImpactSeverity( historical, reprocessed ) {
    for( const rule of impact_severity_rules ) {
      // Caveat: Since recipients can be ranked and then excluded in subsequent steps, we should count those as excluded and ignore the ranking.
      if( historical.excluded ) { historical.actual_rank = null; }
      if( reprocessed.excluded ) { reprocessed.actual_rank = null; }
      const result = rule.evaluate( historical, reprocessed );
      if( result ) {
        return result;
      }
    }
    // Although the "No Impact" rule acts as a catch-all, this return is a failsafe.
    return{ name: "Error", impactNumber: "N/A", message: "An unexpected error occurred during impact assessment." };
  }
};


export default ImpactUtility;
