
const config_json = process.env.NODE_ENV === 'production' ?
  require( './production.json' ) :
  require( './development.json' );

/**
 * @param {string} config_name
 * @returns config: Object
 * @description given a variable name, returns the value if it exists in the config.
 */
const getConfig = function( config_name ) {
  return config_json[ config_name ];
};


module.exports = {
  getConfig
};
