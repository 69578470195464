/**
 * Application Base REST API Service.
 *
 * @copyright Shore
 */

import axios from 'axios';
import { bus } from '../main';
import store from '../store';
import router from '../router';
import { config_facade } from '../../config/config_facade';

/**
 * @function downloadFile
 * @param {string} filename - the (full) name of the file to be downloaded.
 * @param {string} data - the contents of the file.
 * @param {string} contentType - the content type of the file, this can be fetched from header, by default we are expecting application/json.
 * @returns {void}
 * @description - This function is used to download a file from the backend.
 **/
const downloadFile = ( filename, data, contentType = 'application/json' ) => {
  let blob = data;
  blob = new Blob( [ data ], { type: contentType } );
  const link = document.createElement( 'a' );
  link.href = window.URL.createObjectURL( blob );
  link.download = filename;
  link.click();
  link.remove();
};

const successHandler = async response => response;

/**
 * Default error handler interceptor
 * Http errors 500, 401, 403 and 404 are managed from here.
 * All other errors are send back to the application.
 *
 * @param {AxiosError } error
 * @returns
 */
const errorHandler = async error => {
  const { response } = error;

  if( response ) {
    const { status } = response;
    switch( status ) {
      case 400:
        bus.$emit( 'toast-error', response.data ? response.data.error : 'User Error', response.data ? response.data.message : response );
        if( response.config && response.config.url.includes( 'fusion' ) ) {
          router.push( '/login' );
        }
        break;
      case 401:
        bus.$emit( 'toast-error', 'Not Found', response.data ? response.data.error : response );
        //if user is not already in login then logout.
        if( router.currentRoute.path !== '/login' ) {
          store.dispatch( 'logout' );
        }
        // Router.push('/logout');
        break;
      case 404:
        // Router.push('/404');
        break;
      case 500:
        // Router.push('/500');
        break;
      default:
        break;
    }

    return Promise.reject( response );
  }

  return Promise.reject( error );
};

/**
 * Set Authentication Token by default if exists for all outgoing Requests
 *
 * @param {AxiosRequestConfig} config
 * @returns
 */
const requestHandler = config => {
  const token = store.getters.getAccessToken;
  const newConfig = { ...config };

  if( token ) {
    newConfig.headers = {
      ...newConfig.headers,
      authorization: `Bearer ${token}`
    };
  }

  return newConfig;
};
// console.log( config_facade.backend_url );
const service = axios.create( {
  baseURL: config_facade.backend_url
} );

const handleResponseType = ( response, responseType ) => {
  const headers = {};
  // Check the content type
  if( responseType === 'blob' ) {
    const { data } = response;
    //get the filename from the content disposition.
    //fetch the content disposition (this has been set by the cors in the backend in order to see the blob's filename.)
    const contentDisposition = response.headers[ 'content-disposition' ];
    const contentType = response.headers[ 'content-type' ];

    // content-disposition is a string, so we need to parse it to get the filename.
    const match = contentDisposition.match( /filename="(.+)"/ );
    // if none match, then there was no filename sent, so throw an error for now...
    if( match && match.length > 1 ) {
      const filename = match[ 1 ];
      downloadFile( filename, data, contentType );
      return;
    }
    throw new Error( 'No filename found in content disposition' );
  }

  if( responseType === 'multipart/form-data' ) {
    headers[ 'Content-Type' ] = 'multipart/form-data';
  }
  response.headers = headers;
  //if the file is not a blob, then just return the response
  return response;
};
service.interceptors.response.use( successHandler, errorHandler );
service.interceptors.request.use( requestHandler, errorHandler );

const RestClient = () => {
  /**
   * Default Get Service function
   *
   * @param {string} url Base URL
   * @param {QueryParams} params Query Parameters
   * @returns {Promise<Object>}
   */
  const doGet = async ( url, params = null, responseType = 'json' ) => {
    const options = {};
    //Then check if the user has passed any params.
    if( params ) {
      options.params = params;
    }
    const complete_url = config_facade.backend_url + url;
    //check if the responseType is a file that requires downloading.
    // try {
    const response = await service.get(
      complete_url,
      { ...options, responseType: responseType }
    );
    handleResponseType( response, responseType );
    return response.data;
  };

  /**
   * Default Post Service function
   *
   * @param {string} url
   * @param {Object} payload
   *
   * @returns {Promise<Object>}
   */
  const controller = new AbortController();

  const doPost = async ( url, payload, responseType = 'json' ) => {
    //default header is application/json
    let headers = {};
    if( payload instanceof FormData ) {
      headers[ 'Content-Type' ] = 'multipart/form-data';
    } else {
      headers = { "Content-Type": "application/json" };
    }
    // const args = { url: { payload, options: { signal: controller.signal, responseType, headers } } };
    const post_response = await service.post( url, payload, { signal: controller.signal, responseType, headers } );
    // in case the post expects a file, then download it.
    const response = handleResponseType( post_response, responseType );

    return response;
  };

  /**
   * Default Put Service function
   *
   * @param {string} url
   * @param {Object} payload
   * @returns {Promise<Object>}
   */
  const doPut = async ( url, payload ) => {
    const response = await service.put( url, payload );
    return response;
  };

  /**
   * Default Delete Service function
   *
   * @param {string} url
   *
   * @returns {Promise<void>}
   */
  const doDelete = async url => {
    const response = await service.delete( url );
    return response;
  };

  /**
   * Default Patch Service function
   *
   * @param {string} url
   * @param {Object} payload
   * @returns {Promise<Object>}
   */
  const doPatch = async ( url, payload ) => {
    const response = await service.patch( url, payload );
    return response;
  };

  return{
    doGet,
    doPost,
    doPut,
    doDelete,
    doPatch
  };
};


// Functions used with rest client


const client = RestClient();
export default client;
