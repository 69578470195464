/* eslint-disable no-undef */
/**
 * Application Constants
 *
 * @copyright Shore
 */
export default {
  MACHINE_STATES: {
    UNINITIALIZED: 'uninitialized', //default state: when the component is first loaded.
    INITIALIZING:  'initializing',
    INITIALIZED:   'initialized', // initialized happens after component has everything loaded for the first time.
    IDLE:          'idle', //Ready state.
    RUNNING:       'running', //in the process of runnning something
    CANCELLING:    'cancelling', //in the process of cancelling something.
    ERROR:         'error' //error state.
  },
  NAVIGATION_SELECTIONS: {
    GENERAL:          'general',
    ALLOCATION_RULES: 'allocation_rules',
    ADMIN:            'admin'
  },
  MASTER_RULE_URL: '/allocation-rules/master-rules',

  /*
   * metadata_spreadable_properties are the subproperties in the metadata
   * that will end up displaying without showing the path of the
   * properties listed in the array
   */
  metadata_spreadable_properties: [ 'acceptability_properties', 'allocation_properties', 'derived_properties', 'virology_properties' ],
  select:                         {
    rule_types: [
      { label: 'Constants', value: 'constants' },
      { label: 'Ranking Rules', value: 'ranking' },
      { label: 'Exclusion Rules', value: 'exclusion' },
      { label: 'Select Rules', value: 'select' }
    ],
    permissions: [
      { label: 'Enable Forge', value: 'enable-forge' },
      { label: 'Enable Time Machine', value: 'enable-time_machine' },
      { label: 'Enable Catapult', value: 'enable-catapult' },
      { label: 'Admin', value: 'is-admin' }
    ],
    adjust_op: [
      { label: '-', value: '-' },
      { label: '+', value: '+' },
      { label: '*', value: '*' },
      { label: '/', value: '/' },
      { label: '=', value: '=' },
      { label: 'Sort By', value: 'sortby' },
      { label: 'Index', value: 'index' }

    ],
    organs: [
      { value: 1, label: 'Liver' },
      { value: 2, label: 'Heart' },
      { value: 3, label: 'Kidney' },
      { value: 4, label: 'Lung' },
      { value: 6, label: 'Pancreas' },
      { value: 6.5, label: 'Pancreas Islets' },
      { value: 7, label: 'Small Bowel' },
      { value: 7.5, label: 'VCA' }
    ],
    types: [
      { value: 'boolean', label: 'Boolean' },
      { value: 'numeric', label: 'Numeric' },
      { value: 'numeric_array', label: 'Numeric-Array' },
      { value: 'string', label: 'String' },
      { value: 'string_array', label: 'String-Array' },
      { value: 'date', label: 'Date' },
      { value: 'period', label: 'Period' },
      { value: 'dynamic', label: 'Dynamic' }
    ],
    comparators: [
      {
        value:   '=',
        label:   '=',
        allowed: [
          'string',
          'number',
          'boolean',
          'object',
          'date',
          'array',
          'objectid'
        ]
      },
      { value: '<', label: '<', allowed: [ 'number', 'date' ] },
      { value: '<=', label: '<=', allowed: [ 'number', 'date' ] },
      { value: '>', label: '>', allowed: [ 'number', 'date' ] },
      { value: '>=', label: '>=', allowed: [ 'number', 'date' ] },
      {
        value:   '!=',
        label:   'not equal',
        allowed: [ 'string', 'number', 'boolean', 'object', 'date', 'array', 'objectid' ]
      },
      { value: 'in', label: 'in', allowed: [ 'string', 'number', 'boolean', 'object', 'date', 'array' ] },
      { value: '!in', label: 'not in', allowed: [ 'string', 'number', 'boolean', 'object', 'date', 'array' ] },
      { value: 'contains', label: 'contains', allowed: [ 'string', 'number', 'boolean', 'object', 'date', 'array' ] },
      { value: '!contains', label: 'not contained', allowed: [ 'string', 'number', 'boolean', 'object', 'date', 'array' ] },
      {
        value:   'null',
        label:   'null',
        allowed: [
          'string',
          'number',
          'boolean',
          'object',
          'date',
          'array',
          'objectid'
        ]
      },
      {
        value:   '!null',
        label:   'not null',
        allowed: [
          'string',
          'number',
          'boolean',
          'object',
          'date',
          'array',
          'objectid'
        ]
      },

      { value: '!subset', label: 'not subset', allowed: [ 'array' ] },
      {
        value:   'only-loci-+ve',
        label:   'loci positive',
        allowed: [ 'array' ]
      },
      {
        value:   '!only-loci-+ve',
        label:   'not loci positive',
        allowed: [ 'array' ]
      }
    ],
    allocation_types: [
      { label: 'Local', value: 'LOCAL' },
      { label: 'Provincial', value: 'PROVINCIAL' },
      { label: 'Anonymous', value: 'LIVING_ANONYMOUS' },
      { label: 'Null', value: null }
    ],
    allocation_states: [
      { label: 'Recommending', value: 'recommending' },
      { label: 'Recommended', value: 'recommended' },
      { label: 'Concluded', value: 'concluded' },
      { label: 'Offering', value: 'offering' },
      { label: 'Offer Accepted', value: 'offer-accepted' },
      { label: 'Offer Confirmed', value: 'offer-confirmed' },
      { label: 'Discontinued', value: 'discontinued' },
      { label: 'Superceded', value: 'superceded' }
      // { label: 'Error', value: 'error' },
    ]
  }
};
