/* eslint-disable no-shadow */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { Buffer } from 'buffer';

// Tree-shake lodash for needed functions only.
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import _ from 'lodash';
import router from './router';
import RestClient from './services/RestClient';
import constants from './constants';
import ImpactUtility from './services/impactUtility';

Vue.use( Vuex );
const { MACHINE_STATES } = constants;
const state = {
  defaultRankingRules:        [],
  current_time_machine_state: MACHINE_STATES.UNINITIALIZED,
  isLoading:                  false,
  sidebarShow:                'responsive',
  sidebarMinimize:            false,
  metaData:                   '',
  cachedSearch:               { footer: null, result: null },
  select:                     constants.select,
  access_token:               localStorage.getItem( 'access_token' ) || null,
  user:
    localStorage.getItem( 'user' ) !== null && localStorage.getItem( 'user' ) !== undefined ?
      ( JSON.parse( localStorage.getItem( 'user' ) ) ) :
      null,
  is_admin: false
  // hospitals: []
};

const getters = {
  getFieldPropertyEnum: state => fieldPath => {
    const meta = _.get( state.metaData, fieldPath );
    return meta?.enumValues ?? null;
  },
  getFieldPropertyEnums: state => fieldPath => {
    const meta = _.get( state.metaData, fieldPath );
    return meta?.enums ?? null;
  },
  getTimeMachineState( state ) {
    // console.log( 'getting time machine state', state );
    return state.current_time_machine_state; //|| constants.MACHINE_STATES.UNINITIALIZED;
  },
  getAccessToken( state ) {
    return state.access_token;
  },
  getUsername( state ) {
    return state.user ? state.user.username ?? null : null;
  },
  isLoggedIn( state ) {
    return state.access_token !== null && state.access_token !== undefined;
  },
  isAdmin( state ) {
    return state.user !== null && state.user.is_admin;
  },
  isImpactEnabled() {
    //if the environment variable to disable impact is not there then consider it not disabled.
    if( state.user ) {
      const { permissions } = state.user;
      const enable_impact = permissions ? permissions.includes( 'enable-forge' ) : !isNil( process.env.VUE_APP_DISABLE_IMPACT ) ? process.env.VUE_APP_DISABLE_IMPACT.toLowerCase() == 'true' : false;
      return enable_impact;
    }
    return false;
  },
  isTimeMachineEnabled() {
    if( state.user ) {
      const { permissions } = state.user;
      return permissions ? permissions.includes( 'enable-time_machine' ) : false;
    }
    return false;
  },
  isCatapultEnabled() {
    if( state.user ) {
      const { permissions } = state.user;
      return permissions ? permissions.includes( 'enable-catapult' ) : false;
    }
    return false;
  }
};

const mutations = {
  SET_MACHINE_STATE( state, newState ) {
    //check if the new state is valid (belongs to the machine_states enum)
    if( !Object.values( constants.MACHINE_STATES ).includes( newState ) ) {
      throw new Error( `MACHINE_STATE: ${newState} is not a valid machine state.` );
    }
    state.current_time_machine_state = newState;
  },
  auth_success( state, auth_data ) {
    //Store access token in store and localStorage.
    if( !auth_data.username && auth_data.email ) {
      auth_data.username = auth_data.email;
    }
    state.access_token = auth_data.access_token;
    localStorage.setItem( 'access_token', auth_data.access_token );

    //store decoded user data.
    // state.user = auth_data;
    // console.log(auth_data)
    localStorage.setItem( 'user', JSON.stringify( auth_data ) );
    state.user = JSON.parse( localStorage.getItem( 'user' ) );
  },

  auth_logout( state ) {
    state.access_token = null;
    localStorage.removeItem( 'access_token' );

    state.user = null;
    localStorage.removeItem( 'user' );

    //close sidebar if opened.
    state.sidebarShow = false;

    //remove stored authentication from header.
    delete axios.defaults.headers.common.Authorization;
    //reroute back to login.
    router.push( '/login' );
  },

  setLoading( state, value ) {
    state.isLoading = value;
  },


  toggleSidebarDesktop( state ) {
    const sidebarOpened = [ true, 'responsive' ].includes( state.sidebarShow );
    state.sidebarShow = sidebarOpened ? false : 'responsive';
  },

  toggleSidebarMobile( state ) {
    const sidebarClosed = [ false, 'responsive' ].includes( state.sidebarShow );
    state.sidebarShow = sidebarClosed ? true : 'responsive';
  },

  set( state, [ variable, value ] ) {
    state[ variable ] = value;
  }

};

const actions = {
  // hasChildren( { commit }, obj ) { Array.isArray( obj.children ) && obj.children.length > 0; },
  // sortByChildren( { commit }, a, b ) { ( hasChildren( b ) ? 1 : 0 ) - ( hasChildren( a ) ? 1 : 0 ); },
  // sortDataByChildren( { commit }, data ) {
  //   if( Array.isArray( data ) ) {
  //     data.sort( sortByChildren );
  //     data.forEach( sortDataByChildren );
  //   } else if( typeof data === 'object' && data !== null ) {
  //     if( Array.isArray( data.children ) ) {
  //       data.children.sort( sortByChildren );
  //       data.children.forEach( this.sortDataByChildren );
  //     }
  //   // Object.values(data).forEach(this.sortDataByChildren);
  //   }
  // },
  isTimeMachineState( { commit, state }, time_machine_state ) {
    return state.current_time_machine_state === time_machine_state;
  },
  setTimeMachineState( { commit }, newState ) {
    //if there was loading then set it to false.
    commit( 'setLoading', false );
    commit( 'SET_MACHINE_STATE', newState );
  },
  async exportRules( { commit }, rule_sets_to_export ) {
    console.log( 'exporting rules' );
    const response = await RestClient.doPost( '/allocation-rules/export', { rule_sets: rule_sets_to_export }, 'blob' );
  },
  async exportAllRules() {
    //post to backend to download all rules.
    const response = await RestClient.doPost( '/allocation-rules/export', null, 'blob' );
  },
  setLoading( context, value ) {
    context.commit( 'setLoading', value );
  },

  async logout( { commit } ) {
    await commit( 'auth_logout' );
  },
  fusionLogin( { commit, state, dispatch }, loginResponse ) {
    const { access_token, expires_in } = loginResponse.data;

    const is_expired = Date.now > new Date( expires_in );
    //if the token is expired do not authenticate
    if( is_expired ) {
      throw new Error( 'Token is expired.' );
    }
    axios.defaults.headers.common.Authorization =  access_token;
    commit( 'auth_success', { access_token, ...loginResponse.data } );
    dispatch( 'getAndStructureMetadataProperties' );

    router.push( '/' );
  },
  async addAdjustedMetaData( { state, dispatch }, adjusted_metaDatas ) {
    //before adding new adjusted metas, remove any previous _adjusted metas.
    state.accordionData = state.accordionData.filter( prop => prop.path !== '_adjusted' );
    for await ( const [ index, adjusted_meta ] of adjusted_metaDatas.entries() ) {
      const _adjusted_array = adjusted_meta.path.split( '.' );//.splice( 1 ).join( '.' );
      //adjusted fields are labeled as such *._adjusted.* for example recipient._adjusted.age for recipient.age
      _adjusted_array.splice( 1, 0, '_adjusted' );
      const _adjusted = _adjusted_array.join( '.' );

      const { adjusted_rule_id } = adjusted_meta;
      //get type of adjusted field from metaData
      const original_property = _.get( state.metaData, adjusted_meta.path );
      let _type = null;
      let enumValues = null;
      let enums = null;
      let children = null;
      if( original_property ) {
        _type = original_property.type;
        enumValues = original_property.enumValues;
        enums = original_property.enums;
        children = original_property.children;
      }
      const newProperty = {
        id:       _adjusted + index,
        name:     `${_adjusted.split( '.' ).join( '_' )}`,
        path:     _adjusted,
        _type,
        enumValues,
        enums,
        children: children ?? null
      };
      const _adjusted_property = {
        id:         `_adjusted${index}`,
        name:       `_adjusted (${adjusted_rule_id})`,
        path:       '_adjusted',
        _type:      null,
        enumValues: null,
        enums:      null,
        children:   [ newProperty ]
      };

      state.accordionData.push( _adjusted_property );
    }
  },

  async getPropertyTreeObject( { state, dispatch }, Path = null ) {
    try {
      const objArray = [];
      let keys = null;
      let path = Path;
      let currentObject;
      const removeConstantsVersionRegex = /(\.)(\d+_\d+_\d+)(\.)/;
      if( isNil( path ) ) {
        currentObject = state.metaData;
        path = '';
      } else {
        currentObject = _.get( state.metaData, path );
      }
      keys = Object.keys( currentObject );

      if( !isNil( keys ) && isArray( keys ) && keys.length >= 1 ) {
        for( const [ index, child ] of keys.entries() ) {
          const currentPath =
            path === null || path === undefined || _.isEmpty( path ) ?
              `${child}` :
              `${path}.${child}`;
          let hasChildren = false;
          let subObject = _.get( state.metaData, currentPath, {} );
          if( subObject.type ) {
            subObject = subObject.type;
          }
          const subkeys = subObject ?? null;
          hasChildren = !_.isString( subkeys ) && Object.keys( subkeys ).length > 0;
          let children = null;
          if( hasChildren ) {
            children = await dispatch( 'getPropertyTreeObject', currentPath );
          }
          const _type = hasChildren ? null : subObject;

          const newProperty = {
            id:         child + index,
            name:       _.isNumber( child ) && child.name ? child.name : child,
            path:       currentPath,
            _type,
            enums:      _.get( state.metaData, currentPath ).enums ?? null,
            enumValues: _.get( state.metaData, currentPath ).enumValues ?? null,
            children:   children ?? null
          };
          if( newProperty.path.includes( 'constants' ) && ( !newProperty.children || newProperty.children.length > 0 ) ) {
            newProperty.path = newProperty.path.replace( removeConstantsVersionRegex, '.' );
          }

          objArray.push( newProperty );
        }
      }
      //filter by properties that contain subproperties first.
      return objArray.sort( ( a, b ) => b.children !== null );
    } catch( err ) {
      console.log( 'Error getting propertyTree Object' );
      console.log( err.message );
      console.log( err );
    }
  },
  async getAccordionData( { commit, state, dispatch } ) {
    const accordionData = await dispatch( 'getPropertyTreeObject' );
    // clone accordionData to avoid modifying the original object.
    const modifiedAccordionData = _.clone( accordionData );
    const metadata_spreadable_properties =
        constants.metadata_spreadable_properties;

    //traverse the child of each parent on root level.
    for( const prop_original of modifiedAccordionData ) {
      const spreadable_properties = prop_original.children.filter( child => metadata_spreadable_properties.includes( child.name ) );
      //if one of them contains children that is defined as a spreadable object (in constants.metadata_spreadable_properties)
      if( spreadable_properties.length > 0 ) {
        _.remove( prop_original.children, currentObject => metadata_spreadable_properties.includes( currentObject.name ) );
        //for each of the spreadable properties, spread their children to their parent levels.
        for( const prop of spreadable_properties ) {
          //spread the children of the spreadable property to their parent.
          prop_original.children.push( ...prop.children );
          //remove original property that contained the now spreaded data.
          prop.children = prop.children.filter( p => !metadata_spreadable_properties.includes( p.name ) );
        }
      }
    }
    //sort properties before returning
    for( let i = 0; i < modifiedAccordionData.length; i++ ) {
      if( modifiedAccordionData[ i ].children ) {
        await modifiedAccordionData[ i ].children.sort( ( a, b ) => a.children );
      }
    }
    // After building the accordion data, sort the properties by ones that have children first.
    const sorted =  ImpactUtility.sortDataByChildren( modifiedAccordionData );
    return sorted;
  },
  async getCurrentlyUsedRankingRules( { commit, state } ) {
    state.defaultRankingRules = await RestClient.doGet( '/allocation-rules/current-ranking-rules' );
  },
  async getAndStructureMetadataProperties( { commit, state, dispatch } ) {
    try {
      //get metadata from backend.
      const response = await
      RestClient.doGet( `/allocation/metadata` );
      // remove hospitals object from metadata and add to hospitals array
      // this will be used to populate the hospital dropdown in the condition search bar
      // if( response.hospitals ) {
      //   state.hospitals = response.hospitals;
      //   delete response.hospitals;
      // }
      //store metaData object.
      state.metaData = response;
      //filter out any empty objects.
      Object.keys( state.metaData ).forEach( meta => {
        if( Object.keys( state.metaData[ meta ] ).length === 0 ) {
          delete state.metaData[ meta ];
        }
      } );
      state.accordionData = await dispatch( 'getAccordionData' );
    } catch( err ) {
      console.log( `Error Getting Metadata: ${err.message}` );
      console.log( err );
    }
  }
};

export default new Vuex.Store( {
  state,
  getters,
  mutations,
  actions
} );
